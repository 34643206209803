import { configureStore } from "@reduxjs/toolkit";
import { documentService } from "@services/documentService/documentService";
import { utilization } from "@services/utilization/utilization";

export const store = configureStore({
  reducer: {
    [utilization.reducerPath]: utilization.reducer,
    [documentService.reducerPath]: documentService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(utilization.middleware)
      .concat(documentService.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
