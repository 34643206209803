import { createBrowserRouter } from "react-router-dom";
import { ROUTES } from "../enum/path.enum";
import { Suspense, lazy } from "react";
import App from "src/App";
import "src/App.scss";
import Error404 from "@components/UI/Error404/Error404";
import Spinner from "@components/UI/Spinner/Spinner";
import { SPINNER_SIZE } from "@utils/enum/spinner.enum";

const UtilizationPage = lazy(
  () => import("@FSEPublicDashboard/Utilization/Utilization")
);
const DocumentServicesPage = lazy(
  () => import("@FSEPublicDashboard/DocumentServices/DocumentServices")
);

export const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <Error404></Error404>,
    children: [
      {
        path: ROUTES.UTILIZATION,
        element: (
          <Suspense
            fallback={
              <div className="suspense-spinner-container container d-flex justify-content-center">
                <Spinner size={SPINNER_SIZE.SMALL} />
              </div>
            }
          >
            <UtilizationPage />
          </Suspense>
        ),
      },
      {
        path: ROUTES.DOCUMENTSERVICES,
        element: (
          <Suspense
            fallback={
              <div className="suspense-spinner-container container d-flex justify-content-center">
                <Spinner size={SPINNER_SIZE.SMALL} />
              </div>
            }
          >
            <DocumentServicesPage />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
]);
