import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@services/network";
import { DOCUMENT_API_PATH } from "@utils/enum/path.enum";
import {
  GetDocumentsPerRegionResponse,
  GetDocumentsResponse,
  GetServicesPerRegionResponse,
  GetServicesResponse,
} from "./models/documentService.model";
import { transformDocumentResponse } from "@utils/DocumentsServices/transformDocumentResponse";

export const documentService = createApi({
  reducerPath: "documentService",
  baseQuery: axiosBaseQuery({
    baseUrl: "/",
  }),
  endpoints: (build) => ({
    getDocuments: build.query<GetDocumentsResponse, void>({
      query: () => ({
        method: "GET",
        url: DOCUMENT_API_PATH.DOCUMENTS,
      }),
      transformResponse: (response: GetDocumentsResponse) =>
        transformDocumentResponse(response),
    }),
    getDocumentsPerRegion: build.query<GetDocumentsPerRegionResponse, void>({
      query: () => ({
        method: "GET",
        url: DOCUMENT_API_PATH.DOCUMENTS_PER_REGION,
      }),
      transformResponse: (response: GetDocumentsPerRegionResponse) =>
        transformDocumentResponse(response),
    }),
    getServices: build.query<GetServicesResponse, void>({
      query: () => ({
        method: "GET",
        url: DOCUMENT_API_PATH.SERVICES,
      }),
      transformResponse: (response: GetServicesResponse) =>
        transformDocumentResponse(response),
    }),
    getServicesPerRegion: build.query<GetServicesPerRegionResponse, void>({
      query: () => ({
        method: "GET",
        url: DOCUMENT_API_PATH.SERVICES_PER_REGION,
      }),
      transformResponse: (response: GetServicesPerRegionResponse) =>
        transformDocumentResponse(response),
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useLazyGetDocumentsQuery,
  useGetDocumentsPerRegionQuery,
  useLazyGetDocumentsPerRegionQuery,
  useGetServicesQuery,
  useLazyGetServicesQuery,
  useGetServicesPerRegionQuery,
  useLazyGetServicesPerRegionQuery,
} = documentService;
