import * as translationIT from "@/i18n/it.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const defaultNS = "translation";
export const fallbackLng = "it";
export const resources = {
  it: {
    [defaultNS]: translationIT,
  },
} as const;
export const supportedLngs = Object.keys(resources);

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng,
    supportedLngs,
    nonExplicitSupportedLngs: true,
    returnNull: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

i18n.services.formatter?.add("lowercase", (value, lng, options) => {
  return value.toLowerCase();
});
i18n.services.formatter?.add("uppercase", (value, lng, options) => {
  return value.toUpperCase();
});
i18n.services.formatter?.add("capitalize", (value, lng, options) => {
  return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
});

export default i18n;
