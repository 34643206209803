import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@services/network";
import { UTILIZATION_API_PATH } from "@utils/enum/path.enum";
import { transformUtilizationResponse } from "@utils/Utilization/transformUtilizationResponse";
import {
  GetUtilizationCompaniesResponse,
  GetUtilizationConsentResponse,
  GetUtilizationFseResponse,
  GetUtilizationMedicsOpsResponse,
  GetUtilizationMedicsResponse,
} from "./models/utilization.model";

export const utilization = createApi({
  reducerPath: "utilization",
  baseQuery: axiosBaseQuery({
    baseUrl: "/",
  }),
  endpoints: (build) => ({
    getUtilizationFse: build.query<GetUtilizationFseResponse, void>({
      query: () => ({
        method: "GET",
        url: UTILIZATION_API_PATH.FSE,
      }),
      transformResponse: (response: GetUtilizationFseResponse) =>
        transformUtilizationResponse(response),
    }),
    getUtilizationConsent: build.query<GetUtilizationConsentResponse, void>({
      query: () => ({
        method: "GET",
        url: UTILIZATION_API_PATH.CONSENT,
      }),
      transformResponse: (response: GetUtilizationConsentResponse) =>
        transformUtilizationResponse(response),
    }),
    getUtilizationMedics: build.query<GetUtilizationMedicsResponse, void>({
      query: () => ({
        method: "GET",
        url: UTILIZATION_API_PATH.MEDICS,
      }),
      transformResponse: (response: GetUtilizationMedicsResponse) =>
        transformUtilizationResponse(response),
    }),
    getUtilizationMedicsOps: build.query<GetUtilizationMedicsOpsResponse, void>(
      {
        query: () => ({
          method: "GET",
          url: UTILIZATION_API_PATH.MEDICS_OPS,
        }),
        transformResponse: (response: GetUtilizationMedicsOpsResponse) =>
          transformUtilizationResponse(response),
      }
    ),
    getUtilizationCompanies: build.query<GetUtilizationCompaniesResponse, void>(
      {
        query: () => ({
          method: "GET",
          url: UTILIZATION_API_PATH.COMPANIES,
        }),
        transformResponse: (response: GetUtilizationCompaniesResponse) =>
          transformUtilizationResponse(response),
      }
    ),
  }),
});

export const {
  useGetUtilizationFseQuery,
  useLazyGetUtilizationFseQuery,
  useGetUtilizationConsentQuery,
  useLazyGetUtilizationConsentQuery,
  useGetUtilizationMedicsQuery,
  useLazyGetUtilizationMedicsQuery,
  useGetUtilizationMedicsOpsQuery,
  useLazyGetUtilizationMedicsOpsQuery,
  useGetUtilizationCompaniesQuery,
  useLazyGetUtilizationCompaniesQuery,
} = utilization;
