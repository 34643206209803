import { FC, memo } from "react";
import "./Spinner.scss";
import { SpinnerProps } from "./models/Spinner.model";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Spinner: FC<SpinnerProps> = ({ size }) => {
  const { t } = useTranslation();
  const buttonClass = classNames("spinner", size ? `spinner-${size}` : null);

  return (
    <div data-testid="spinner" className={buttonClass} role="status">
      <div className="spinner-border text-primary"></div>
      <span className="visually-hidden">
        {t("shared.spinner.hiddenCaption")}
      </span>
    </div>
  );
};

export default memo(Spinner);
