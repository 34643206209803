import { FC, memo } from "react";
import { IconProps } from "./models/Icon.model";
import classNames from "classnames";

const Icon: FC<IconProps> = ({ iconClass, customClass, id }) => {
  const iconClassName = classNames(iconClass, customClass);

  return <i className={iconClassName} data-testid={id} />;
};

export default memo(Icon);
