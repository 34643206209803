import { EXTERNAL_LINKS } from "@utils/enum/path.enum";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Header.scss";
import LogoGovBianco from "./icons/LogoGovBianco";
import { memo } from "react";

const Header = () => {
  const { t } = useTranslation();

  return (
    <header aria-label="Informazioni e strumenti utente">
      <div
        className="Header header-top-dark d-none d-md-block"
        data-testid="header"
      >
        <div className="Header__links container-mcw container-fluid py-2">
          <Link
            to={EXTERNAL_LINKS.HEALTH_MINISTRY}
            className="Header__links--link link-white"
          >
            {t("shared.header.linkHealthMinistry")}
          </Link>
          {` ${t("shared.and")} `}
          <Link
            to={EXTERNAL_LINKS.DIGITAL_TRASFORMATION_DEPARTMENT}
            className="Header__links--link link-white"
          >
            {t("shared.header.linkDigitalTransformationDepartment")}
          </Link>
        </div>
      </div>
      <div className="Header__titleBox header-dark">
        <div className="container-mcw container-fluid py-3 py-md-4">
          <div className="row align-items-center m-auto">
            <div className="col-auto">
              <LogoGovBianco />
            </div>
            <div className="col">
              <h1 id="title_header">{t("shared.header.title")}</h1>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default memo(Header);
