import { SectionCard } from "@models/interface/sectionCard.model";

export const transformDocumentResponse = (serviceResponse: SectionCard) => {
  return {
    ...serviceResponse,
    data: serviceResponse.data.map((itemData) => {
      return {
        ...itemData,
        valuePercent: `${itemData.value}%`,
        detailDescriptions: itemData.detail
          ? itemData.detail.map((item) => item.description).join(", ")
          : "",
      };
    }),
  };
};
