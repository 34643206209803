import Footer from "@components/UI/Footer/Footer";
import Header from "@components/UI/Header/Header";
import React from "react";
import { Outlet } from "react-router-dom";
import "./Layout.scss";
import ScrollToTop from "@components/UI/ScrollToTop/ScrollToTop";
import { scrollToTop } from "@utils/scrollToTop";

const Layout: React.FC = () => {
  return (
    <div className="Layout">
      <Header />
      <div
        data-testid="outlet-container"
        className="container-mcw container-fluid px-0"
      >
        <Outlet />
      </div>
      <Footer />
      <ScrollToTop onClick={scrollToTop} data-testid="scroll-to-top" />
    </div>
  );
};

export default Layout;
