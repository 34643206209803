import { FC, memo } from "react";
import "./Error404.scss";
import { Error404Props } from "./models/Error404.model";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "@utils/enum/path.enum";

const Error404: FC<Error404Props> = ({}) => {
  const { t } = useTranslation();

  return (
    <div data-testid="error404" className="Error404 min-vh-100">
      <main>
        <div role="main" className="container-fluid">
          <div className="row px-2 py-5">
            <div className="col">
              <h1 className="Error404__title bs-gray-900 fw-bold">
                {t("shared.error.pageNotFound")}
              </h1>
              <div className="Error404__description bs-gray-900 my-4">
                <div>{t("shared.error.pageNotFoundMainDescription")}</div>
              </div>
              <div className="Error404__description bs-gray-900 my-4 pb-2">
                <div>{t("shared.error.pageNotFoundSubDescription")}</div>
              </div>
              <div className="col mt-5">
                <Link to={ROUTES.DOCUMENTSERVICES} className="fw-bold">
                  {t("shared.error.backToDocumentServices")}
                </Link>
              </div>
              <div className="col mt-4 mb-5">
                <Link to={ROUTES.UTILIZATION} className="fw-bold">
                  {t("shared.error.backToUsage")}
                </Link>
              </div>
            </div>
            <div className="col py-3">
              <img
                src="/assets/img/logo-fse.png"
                alt=""
                className="Error404__logo"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default memo(Error404);
