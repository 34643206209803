import { SectionCard } from "@models/interface/sectionCard.model";

export const transformUtilizationResponse = (serviceResponse: SectionCard) => {
  return {
    ...serviceResponse,
    data: serviceResponse.data.map((itemData) => ({
      ...itemData,
      valuePercent: `${itemData.value}%`,
    })),
  };
};
